import clsx from 'clsx';
import dayjs from 'dayjs';
import { defineComponent } from '../../libs';
import { ocgDatetimeToDates } from '../../libs/date';
import { mapContext } from '../../store';

function sortByDate(a, b) {
  if (a.datetime < b.datetime) {
    return -1;
  }
  if (a.datetime > b.datetime) {
    return 1;
  }
  return 0;
}

function getContainedCount(items, index) {
  const [curStart, _] = ocgDatetimeToDates(items[index].datetime);
  let count = 0;
  for (let i = 0; i < index; i++) {
    const [currentStart, currentEnd] = ocgDatetimeToDates(items[i].datetime);
    if (currentStart.getTime() < curStart.getTime() && currentEnd.getTime() > curStart.getTime()) {
      count++;
    }
  }
  return count;
}

/**
 * Timeslider inner component for display items
 */
defineComponent('[data-items-display]', {
  initialize() {
    this.handleItemSelection = this.handleItemSelection.bind(this);
    this.handleHoverItem = this.handleHoverItem.bind(this);
    this.handleOutItem = this.handleOutItem.bind(this);
  },
  handleItemSelection(ev) {
    ev.preventDefault();
    const date = ev.target.getAttribute('data-date');
    // eslint-disable-next-line no-console
    console.log(date);
  },
  handleHoverItem(ev) {
    const date = ev.target.getAttribute('data-date');
    mapContext.value.map.fire('date-over', { date }, true);
  },
  handleOutItem() {
    mapContext.value.map.fire('date-out', undefined, true);
  },
  render(element) {
    const { itemsData } = this.store.itemsStore;
    const { campaigns } = this.store.campaignStore;
    if (!itemsData && !campaigns) {
      this.html`<!-- items-display -->`;
      return;
    }
    if (itemsData) {
      this.renderItems(element);
      return;
    }
    this.renderCampaigns(element);
  },
  renderItems(element) {
    const { width } = element.getBoundingClientRect();
    const { curDays } = this.store.timeRangeStore;
    const { itemsDates } = this.store.itemsStore;
    const [from, to] = this.store.timeRangeStore.lastDateSelection;
    const dateFrom = dayjs(from);
    const dateTo = dayjs(to);
    const diffDays = dateTo.diff(dateFrom, 'day');
    const chunkRealSize = width / diffDays;
    const chunkSize = Math.max(chunkRealSize, 1);
    const itemAreas = Array.from(Array(diffDays).keys());
    const matchDays = itemsDates
      // .map((datetime) => ocgDatetimeToDates(datetime)[1].toISOString().slice(0, 10))
      .sort();
    const marginSize = chunkSize >= 6 ? 1 : 0;
    this.html`${itemAreas
      .map((i) => {
        const curDay = dateFrom.add(i, 'day');
        const matchIndex = matchDays.indexOf(curDay.format('YYYY-MM-DD'));
        if (matchIndex === -1) {
          return null;
        }
        const color = '231,70,49';
        const contrastColor = '251,191,36';
        return this.$`<button data-date="${curDays[i]}" class="${clsx(
          'block absolute top-0 bottom-0 opacity-60 rounded-sm hover:border-yellow-600',
          {
            'hover:opacity-80': element.dragging === null,
          }
        )}" style=${`width: ${
          chunkSize >= 6 ? chunkSize - 2 : chunkSize
        }; margin: 6 ${marginSize}px; left: ${
          i * chunkRealSize
        }px; background-color: rgb(${color}); border-color:rgb(${contrastColor})`}
        onclick=${this.handleItemSelection} onmouseover=${this.handleHoverItem} onmouseout=${
          this.handleOutItem
        }></button>`;
      })
      .filter((n) => n)}`;
  },
  renderCampaigns(element) {
    const { width } = element.getBoundingClientRect();
    const { campaigns } = this.store.campaignStore;
    const items = campaigns.features.map((f) => ({ ...f.properties })).sort(sortByDate);
    const [from, to] = this.store.timeRangeStore.lastDateSelection;
    const dateFrom = dayjs(from);
    const dateTo = dayjs(to);
    const diffDays = dateTo.diff(dateFrom, 'day');
    const chunkSize = Math.max(width / diffDays, 1);
    const marginSize = chunkSize >= 6 ? 1 : 0;

    this.html`${items.map(
      ({ datetime, color = '155,202,215	', contrastColor = '59, 130, 246' }, index) => {
        const dates = ocgDatetimeToDates(datetime, {
          min: dateFrom.toDate(),
          max: dateTo.toDate(),
        });
        let dateStart = dayjs(dates[0]);
        if (dateFrom.isAfter(dateStart)) {
          dateStart = dateFrom;
        }
        let dateEnd = dayjs(dates[1]);
        if (dateTo.isBefore(dateEnd)) {
          dateEnd = dateTo;
        }
        const rangeDays = dateEnd.diff(dateStart, 'day');
        const leftDays = dateStart.diff(dateFrom, 'day');
        // diffDays : width = rangeDays : x
        const periodWidth = (width * rangeDays) / diffDays;
        // leftDays : diffDays = x : width
        const left = (width * leftDays) / diffDays;
        return this
          .$`<button data-item-period="${datetime}" class="${clsx(
          'block absolute bottom-0 rounded-md border',
          { 'hover:opacity-80': element.dragging === null }
        )}" style=${`width: ${periodWidth}; margin: 6 ${marginSize}px; left: ${left}px; background-color: rgb(${color}); border-color:rgb(${contrastColor}); top: ${
          getContainedCount(items, index) * 5
        }px`}></button>`;
      }
    )}`;
  },
});
