import { makeAutoObservable } from 'mobx';

/**
 * Store for product's specific data
 */
class ProductStore {
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  // Selected product in the sidenav
  selectedProduct = null;
  // Available products
  // product record is composed by { polygon, name, color, contrastColor }
  products = {};

  // Currently loaded product
  currentProduct = null;

  setSelectedProduct(id) {
    this.selectedProduct = id;
  }

  addProduct(id, product) {
    this.products = { ...this.products, [id]: product };
  }

  setCurrentProduct(data) {
    this.currentProduct = data;
  }
}

export default ProductStore;
