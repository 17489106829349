import { render, html, svg } from 'uhtml';
import { define, render as hookedRender } from 'hooked-elements';
import { reaction } from 'mobx';

import appState from '../store';

const MicroHTML = {
  html() {
    return render(this.element, html.apply(null, arguments));
  },
  svg() {
    return render(this.element, svg.apply(null, arguments));
  },
};

export function defineComponent(selector, options = {}) {
  const { initialize, ...rest } = options;
  requestAnimationFrame(() => {
    return define(selector, {
      ...MicroHTML,
      init() {
        this._originalHTML = this.element.innerHTML;
        this.textContent = this.element.textContent;
        this.store = appState;
        if (initialize) {
          initialize.bind(this)();
        }
        reaction(
          () => ({
            ...appState.productStore,
            ...appState.timeRangeStore,
            ...appState.campaignStore,
            ...appState.mapStore,
            ...appState.itemsStore,
          }),
          (state) => {
            this.render(this.element, state);
          }
        );
        hookedRender(this);
      },
      /**
       * Default render method (which do nothing at all)
       *
       * @param {DOMEmement} element probably the "where" caluse from uhtml.render
       * @param {DOMElement} what probably the "what" caluse from uhtml.render
       * @param {Mobx} state mobx state object
       */
      render(element, what, state) {
        // this.html(this._originalHTML)`;
      },
      $() {
        return html.apply(null, arguments);
      },
      $null(text = '') {
        return html([`<!-- ${text} -->`]);
      },
      for() {
        return html.for.apply(null, arguments);
      },
      ...rest,
    });
  });
}
