import { defineComponent } from '../../libs';

/**
 * Timeslider inner start/from
 */
defineComponent('[data-date-from-to]', {
  // observedAttributes: ['from', 'to'],
  // attributeChanged(name, oldValue, newValue) {
  //   console.log(name);
  //   this[name] = newValue;
  // },
  render(element) {
    const { from = null, to = null } = element;
    this.html`<div class="ml-2" data-date-from>${
      from !== null ? `from ${from}` : this.$`&nbsp;`
    }</div>
<div class="mr-2" data-date-to>${from !== null ? `to ${to}` : this.$`&nbsp;`}</div>`;
  },
});
