import { makeAutoObservable, flow } from 'mobx';
import { service } from '../libs/ogc';

function getMaxRange(geojson) {
  const { features } = geojson;
  let min = null;
  let max = null;
  features.forEach(({ properties }) => {
    const [start, end] = properties.datetime.split('/');
    if (!min || start < min) {
      min = start;
    }
    if (!max || end > max) {
      max = end;
    }
  });
  return `${min}/${max}`;
}

/**
 * Store for campaign's specific data
 */
class CampaignStore {
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  // status can be unstarted, loading, loaded, error
  status = 'unstarted';

  // All loaded campaigns
  campaigns = null;

  fetchCampaigns = flow(function* (bbox, datetime) {
    this.status = 'loading';
    try {
      const campaigns = yield service.getFeatures('campaigns', bbox, datetime);
      this.status = 'loaded';
      this.campaigns = campaigns;
      this.rootStore.timeRangeStore.init(getMaxRange(campaigns));
    } catch (error) {
      this.status = 'error';
      this.reason = error.toString();
      console.error(error.stack);
    }
  });

  // Selected campaign data (focused on map)
  selected = null;
  data = null;
  // error reason
  reason = null;

  setSelected(selected) {
    if (!selected) {
      this.selected = null;
      this.rootStore.itemsStore.clearItems();
      return;
    }
    const { href } = selected;
    if (href) {
      const id = href.split('/').pop();
      this.selected = id;
      this.data = selected;
      this.rootStore.itemsStore.fetchItems(id);
    }
  }
}

export default CampaignStore;
