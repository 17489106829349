import { configure } from 'mobx';
import { createContext } from 'hooked-elements';

import ProductStore from './product';
import TimeRangeStore from './timerange';
import CampaignStore from './campaign';
import MapStore from './map';
import ItemsStore from './items';

configure({
  enforceActions: 'always',
  computedRequiresReaction: false,
  reactionRequiresObservable: true,
  observableRequiresReaction: false,
  disableErrorBoundaries: process.env.NODE_ENV === 'test',
  safeDescriptors: !process.env.NODE_ENV === 'test',
});

class AppStore {
  constructor() {
    this.productStore = new ProductStore(this);
    this.timeRangeStore = new TimeRangeStore(this);
    this.campaignStore = new CampaignStore(this);
    this.mapStore = new MapStore(this);
    this.itemsStore = new ItemsStore(this);
  }
}

const appState = new AppStore();

export default appState;

export const appContext = createContext({
  root: null,
});

export const mapContext = createContext({
  map: null,
  component: null,
});
