import { useContext } from 'hooked-elements';
import { defineComponent } from '../../libs/index';
import { mapContext } from '../../store';

defineComponent('[data-campaign-details]', {
  initialize() {
    this.handleClose = this.handleClose.bind(this);
  },
  handleClose() {
    const { store } = this;
    store.campaignStore.setSelected(null);
    this.context.map.flyTo(store.mapStore.center, store.mapStore.zoom, {
      easeLinearity: 1,
      duration: 0.25,
    });
  },
  renderData(products, title) {
    return this.$`<div class="p-1.5 shadow-md">
      <h3 class="font-bold">${title}</h3>
      ${Object.entries(products).map(([key, val]) => {
        if (Object.keys(val).length === 0) {
          return this.$`<!-- skp -->`;
        }
        return this.$`<div>
          <h4 class="text-sm font-semibold border-b border-black border-opacity-50">${key}</h4>
          ${this.renderSection(val)}
        </div>
        `;
      })}
    </div>`;
  },
  renderSection(section) {
    return this.$`<div>
      ${Object.entries(section).map(([key, val]) => {
        return this.$`<div class="text-sm">
          <h5 class="inline-block font-semibold">${key}</h5>:
          ${this.renderItem(val)}
        </div>
        `;
      })}
    </div>`;
  },
  renderItem(item) {
    return this.$`${item.toString()}`;
  },
  render() {
    const { campaignStore } = this.store;
    const { name, products, observations } = campaignStore.data;
    this.context = useContext(mapContext);
    if (campaignStore.status === 'loading') {
      this
        .html`<div aria-live="polite" class="md:absolute inset-0 flex justify-center items-center text-gray-500 bg-white bg-opacity-90 z-20 text-4xl">
    <i class="fas fa-circle-notch fa-lg fa-spin mr-1.5"></i> Loading…
  </div>`;
      return;
    }
    if (campaignStore.status === 'loaded') {
      this.html`<div class="display-grid">
  <h2 class="text-lg font-bold" ref=${(node) => {
    node.innerHTML = name;
  }}></h2>
      <div>
      ${observations ? this.renderData(observations, 'Observations') : ''}
      ${products ? this.renderData(products, 'Products') : ''}
      </div>
  <button class="justify-self-start text-gray-400 ml-2 mb-2"><i class="fas fa-arrow-left fa-2x"
        onclick=${this.handleClose}></i></button>
      </div>`;
      return;
    }
    if (campaignStore.status === 'error') {
      this
        .html`<section role="alert" data-sys-message-type="error">${campaignStore.reason}</section>`;
      return;
    }
    this.html`<!-- campaign-details -->`;
  },
});
