import { makeAutoObservable, flow, computed } from 'mobx';
import request from '../libs/request';
import { service } from '../libs/ogc';
import { ocgDatetimeToDates, getDaysArray } from '../libs/date';

/**
 * Store for current item and all loaded items data
 */
class ItemsStore {
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false, itemsDates: computed });
    this.rootStore = rootStore;
  }

  status = 'unstarted';
  reason = null;

  setStatus(status, reason) {
    this.status = status;
    this.reason = reason;
  }

  itemsData = null;
  fetchItems = flow(function* (id) {
    this.status = 'loading';
    // Let's load current range dates
    const start = this.rootStore.timeRangeStore.curDays[0];
    const end = this.rootStore.timeRangeStore.curDays[
      this.rootStore.timeRangeStore.curDays.length - 1
    ];
    try {
      const campaignItems = yield service.getFeatures(id, {
        datetime: {
          start: new Date(start),
          end: new Date(end),
        },
      });
      this.status = 'loaded';
      this.reason = null;
      this.itemsData = campaignItems;
    } catch (error) {
      this.status = 'error';
      this.reason = error.toString();
      console.error(error.stack);
    }
  });

  clearItems() {
    this.itemsData = null;
  }

  // Every possible date of the current items set
  get itemsDates() {
    const { itemsData } = this;
    const { features = [] } = itemsData;
    if (features.length === 0) {
      return [];
    }
    let dates = [];
    features.forEach((feature) => {
      const { properties = {} } = feature;
      const { datetimes = [] } = properties;
      datetimes.forEach((datetime) => {
        const nextDates = getDaysArray(...ocgDatetimeToDates(datetime));
        dates = [...dates, ...nextDates.map((date) => date.toISOString().substring(0, 10))];
      });
      // dates = [...dates, ...datetimes];
    });
    return Array.from(new Set(dates.sort()));
  }

  // ******* selected item *******
  selectedItem = null;
  itemData = null;
  setSelected(selected) {
    if (!selected) {
      this.selectedItem = selected;
      this.itemData = null;
      return;
    }
    const { href } = selected;
    this.selectedItem = selected;
    if (href) {
      this.fetchItemData(href);
    }
  }

  fetchItemData = flow(function* (href) {
    this.status = 'loading';
    this.reason = 'Loading data…';
    try {
      const itemData = yield request(href);
      // We don't reset the status because we have to download other nested resources
      // this.status = 'loaded';
      this.reason = 'Loading resources…';
      this.itemData = itemData;
    } catch (error) {
      this.status = 'error';
      this.reason = error.toString();
      console.error(error.stack);
    }
  });
}

export default ItemsStore;
