/* eslint-disable no-unmodified-loop-condition */
export function getDaysArray(start, end) {
  let arr;
  let dt;
  for (arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
}

/**
 * Converts an OGC datetime string into dates.
 * @param {String} datetime
 * @options {Object} a {min, max} literal of (Date objects) to be used for converting open start/end (the ".." value)
 *                   If open end, the max default is the current date, while open start is Unix time.
 */
export function ocgDatetimeToDates(datetime, options = {}) {
  const { min = new Date(0), max = new Date() } = options;
  const [start, end] = datetime.split('/');
  const startDate = start === '..' ? min : new Date(start);
  const endDate = end === '..' ? max : new Date(end);
  return [startDate, endDate];
}
