import clsx from 'clsx';
import { useContext, useState, useEffect } from 'hooked-elements';
import { defineComponent } from '../../libs';
import { appContext } from '../../store';
import { MAX_VALUE } from '../../store/timerange';

import './DateFromTo';
import './ItemsDisplay';

function getOffset(element, value, right = false) {
  const { width } = element.getBoundingClientRect();
  // value : MAX_VALUE = x : width
  const offset = ((right ? MAX_VALUE - value : value) * (width - 8)) / MAX_VALUE;
  return offset;
}

/**
 * Timeslider section component
 */
defineComponent('[data-timeslider]', {
  initialize() {
    // const element = this.element;
    // const minDate = element.getAttribute('data-range-min');
    // const maxDate = element.getAttribute('data-range-max');
    this.store.timeRangeStore.init('1970-01-01/..');
    this.store.timeRangeStore.setStartRangeValue(0);
    this.store.timeRangeStore.setEndRangeValue(MAX_VALUE);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.dragEnd = this.dragEnd.bind(this);
  },
  disconnected() {
    const root = this.context.root;
    root.removeEventListener('mouseup', this.handleMouseUp);
    root.removeEventListener('mouseout', this.handleMouseOut);
    root.removeEventListener('mousemove', this.handleMouseMove);
  },
  // ******* drag&drop management *******
  handleMouseUp(ev) {
    if (this.dragging) {
      this.dragEnd(ev);
    }
  },
  handleMouseOut(ev) {
    if (ev.target === this.context.root && this.dragging) {
      this.dragEnd(ev);
    }
  },
  dragEnd() {
    this.setDragging(null);
    this.element.classList.remove('cursor-col-resize');
    this.store.timeRangeStore.recalcDateRange();
  },
  handleMouseMove(event) {
    requestAnimationFrame(() => {
      if (!this.dragging) {
        return;
      }
      const { width, left, right } = this.element.getBoundingClientRect();
      const nextX = this.dragging === 'right' ? right - event.pageX : event.pageX - left;
      if (nextX < 0 || nextX > width - 8) {
        return;
      }
      // nextX : width = x : MAX_VALUE
      let value = parseInt((nextX * MAX_VALUE) / (width - 8), 10);
      if (this.dragging === 'right') {
        value = MAX_VALUE - value;
      }
      const padding = parseInt((8 * MAX_VALUE) / width, 10);
      if (
        (this.dragging === 'left' &&
          value >= this.store.timeRangeStore.rangeSelection[1] - padding) ||
        (this.dragging === 'right' &&
          value <= this.store.timeRangeStore.rangeSelection[0] + padding)
      ) {
        return;
      }
      if (this.dragging === 'left') {
        this.store.timeRangeStore.setStartRangeValue(value);
      } else {
        this.store.timeRangeStore.setEndRangeValue(value);
      }
    });
  },
  handleDragStart(position) {
    return (ev) => {
      ev.stopPropagation();
      this.setDragging(position);
      this.element.classList.add('cursor-col-resize');
    };
  },
  // ******* / drag&drop management *******
  render(element) {
    const [dragging, setDragging] = useState(null);
    this.dragging = dragging;
    this.setDragging = setDragging;
    this.context = useContext(appContext);
    const [start, end] = this.store.timeRangeStore.rangeSelection;
    const { status } = this.store.timeRangeStore;
    const loading = status === 'loading';
    const leftOffset = getOffset(element, start);
    const rightOffset = getOffset(element, end, true);
    useEffect(() => {
      // Adding event handlers for drag&drop
      const root = this.context.root;
      if (root) {
        root.addEventListener('mouseup', this.handleMouseUp);
        root.addEventListener('mouseout', this.handleMouseOut);
        root.addEventListener('mousemove', this.handleMouseMove);
      }
    }, [this.context]);
    this.html`<div class=${clsx('relative', { 'pointer-events-none': loading })} aria-busy="${
      loading ? 'true' : 'false'
    }">
  <div class="relative z-10 select-none">
    <div class="timeslider"></div>
    <div class="absolute inset-0" .dragging=${dragging} data-items-display></div>
    <button role="slider" class="timeslider-slider" data-timeslider-pos="left"
            onmousedown=${this.handleDragStart('left')} aria=${{
      'aria-grabbed': dragging === 'left',
    }} style=${`left:${leftOffset}px`}></button>
    <button role="slider" class="timeslider-slider right-0" data-timeslider-pos="right"
            onmousedown=${this.handleDragStart('right')} aria=${{
      'aria-grabbed': dragging === 'right',
    }} style=${`right:${rightOffset}px`}></button>
  </div>
  <div class="mt-1 flex flex-row justify-between text-gray-500 text-sm" data-date-from-to .from=${
    this.store.timeRangeStore.dateSelection[0]
  } .to=${this.store.timeRangeStore.dateSelection[1]}>
  </div>
  <div aria-live="polite" class=${clsx(
    'absolute inset-0 justify-center items-center text-gray-500 bg-white bg-opacity-90 z-20 text-4xl',
    { flex: loading }
  )}
      ?hidden=${!loading}>
    <i class="fas fa-circle-notch fa-lg fa-spin mr-2"></i> Loading…
  </div>
</div>`;
  },
});
