import clsx from 'clsx';
import { defineComponent } from '../../libs/index';

defineComponent('[data-product-selector]', {
  initialize() {
    const element = this.element;
    const polygons = element.getAttribute('data-polygons');
    const productId = element.getAttribute('data-product-id');
    const color = element.getAttribute('data-color') || 'blue';
    const contrastColor = element.getAttribute('data-contrast-color') || 'red';
    if (polygons && productId) {
      const geojson = JSON.parse(polygons);
      this.store.productStore.addProduct(productId, {
        geojson,
        name: this.textContent,
        color,
        contrastColor,
      });
    }
  },
  onClick(event) {
    event.preventDefault();
    const id = this.element.getAttribute('data-product-id');
    this.store.productStore.setSelectedProduct(
      id === this.store.productStore.selectedProduct ? null : id
    );
  },
});

defineComponent('[data-products-nav]', {
  render() {
    const showItem = Boolean(this.store.itemsStore.selectedItem);
    const showCampaign = Boolean(!showItem && this.store.campaignStore.selected);
    this.html`${Object.entries(this.store.productStore.products).map(([id, { name, color }]) => {
      const isSelected = this.store.productStore.selectedProduct === id;
      return this.$`<button
        class="${clsx('prod-item-btn', {
          'prod-selected': isSelected,
        })}"
        style=${`background-color: rgba(${color},0.45)`}
        data-product-selector
        data-product-id="${id}"
        data-color="${color}"
        data-selected=${isSelected}
      >
        ${name}
      </button>`;
    })}
    ${
      showItem
        ? this.$`<aside
      data-item-details class="md:absolute z-10 -inset-1.5 bg-gray-50 bg-opacity-80 p-0.5 border border-gray-300 rounded-md"></aside>`
        : this.$null('items-details')
    }
    ${
      showCampaign
        ? this.$`<aside
      data-campaign-details class="md:absolute z-10 -inset-1.5 bg-gray-50 bg-opacity-80 p-0.5 border border-gray-300 rounded-md"></aside>`
        : this.$null('campaign-details')
    }`;
  },
});
