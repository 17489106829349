const PUBLIC_URL = process.env.PUBLIC_URL;
const API_BASE_URL = process.env.API_BASE_URL;

export function getBaseURL() {
  return PUBLIC_URL || window.webapp_base_url || '';
}

export function getAPIBaseURL() {
  return API_BASE_URL || window.api_base_url || getBaseURL();
}
