import { useEffect, useState } from 'hooked-elements';
import { defineComponent } from '../../libs';
import request from '../../libs/request';

function generatePromise(linkData) {
  const { href, title: category = linkData.href } = linkData;
  return new Promise((resolve, reject) => {
    request(href)
      .then((data) => {
        const { assets = {} } = data;
        const resources = Object.values(assets).map(({ href, title, type }) => ({
          href,
          title,
          type,
        }));
        resolve({
          category,
          assets: resources,
        });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}

/**
 * Overlay for item details
 */
defineComponent('[data-item-details]', {
  initialize() {
    this.handleClose = this.handleClose.bind(this);
  },
  handleClose() {
    this.store.itemsStore.setSelected(null);
    this.store.itemsStore.clearItems();
    // this.context.map.flyTo(store.mapStore.center, store.mapStore.zoom, {
    //   easeLinearity: 1,
    //   duration: 0.25,
    // });
  },
  render() {
    const { store } = this;
    const { status, reason, itemData } = store.itemsStore;
    const [links, setLinks] = useState([]);
    const [resources, setResources] = useState([]);
    useEffect(() => {
      if (!itemData) {
        setLinks([]);
        return;
      }
      const { links = [] } = itemData;
      setLinks(links.filter((link) => link.rel === 'item').map((link) => generatePromise(link)));
    }, [itemData]);
    useEffect(() => {
      if (links.length === 0) {
        return;
      }
      async function waitAll() {
        const results = await Promise.allSettled(links);

        setResources(
          results
            .filter((result) => result.status === 'fulfilled')
            .map((resData) => {
              const { category, assets } = resData.value;
              return {
                category,
                resources: assets,
              };
            })
        );
        store.itemsStore.setStatus('loaded', null);
      }
      waitAll();
    }, [links]);

    if (status === 'loading') {
      this
        .html`<div aria-live="polite" class="md:absolute inset-0 flex justify-center items-center text-gray-500 bg-white bg-opacity-95 z-20 text-3xl">
    <i class="fas fa-circle-notch fa-lg fa-spin mr-1.5"></i> ${reason}
  </div>`;
      return;
    }
    if (status === 'loaded' && itemData) {
      this.html`<div class="display-grid item-sheet text-sm">
  <h2 class="text-lg font-bold">${itemData.id}</h2>
  ${
    resources.length > 0
      ? this.$`<ul class="item-resources">${resources.map(({ category, resources }) => {
          return this.$`<li><div class="p-1.5 shadow-md">
  <div class="text-base font-medium">${category}</div>
  <ul>${resources.map((resource) => {
    const { href, title, type } = resource;
    return this
      .$`<li class="item-resource"><a class="hover:text-gray-500" target="_blank" rel="noopener noreferrer" href="${href}" type="${type}" title="${title}">
  ${title}
</a></li>`;
  })}</ul>
</div></li>`;
        })}</ul>`
      : this.$null('item-resources')
  }  
  <button class="text-gray-400 ml-2 mb-2 justify-self-start"><i class="fas fa-arrow-left fa-2x"
    onclick=${this.handleClose}></i></button>
      </div>
`;
      return;
    }
    if (status === 'error') {
      this
        .html`<section role="alert" data-sys-message-type="error">${this.store.itemsStore.reason}</section>
<button class="absolute top-0 right-0 text-gray-400 mr-5 mt-1"><i class="fas fa-times fa-2x"
        onclick=${this.handleClose}></i></button>`;
      return;
    }
    this.html`<!-- item-details -->`;
  },
});
