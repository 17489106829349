import { defineComponent } from './libs';
import './styles/index.scss';
import { appContext } from './store';

import './components/Map';
import './components/ProductsNavigation';
import './components/TimeSlider';
import './components/AsideCampaignDetails';
import './components/ItemDetails';

defineComponent('[data-app]', {
  initialize() {
    appContext.provide({ root: this.element });
  },
});
