/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
export const parseJSON = (response) => {
  // .then((json) => (response.ok ? json : Promise.reject(json)))
  if (response.status === 204) {
    // no content
    return null;
  }
  return response.json();
};

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
export const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;

  if (response.status === 403 || response.status === 401) {
    error.access_error = response.status;
  }

  if (response.status === 404) {
    error.not_found = response.status;
  }

  throw error;
};

function delay(response) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(response);
    }, Math.floor(Math.random() * (800 - 200) + 200));
  });
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options = {}) {
  // options.headers = { ...options.headers, 'Cache-Control': 'no-cache, no-store' };
  return fetch(url, { ...options })
    .then(checkStatus)
    .then(parseJSON)
    .then(delay);
}
