import { useEffect } from 'hooked-elements';

import MapSupport from './map';
import { defineComponent } from '../../libs';

defineComponent('[data-map]', {
  ...MapSupport,
  initialize() {
    this._mapElement = this.element.querySelector('.flair-map');
    this.polygons = [];
    this.initMap();
    this.store.campaignStore.fetchCampaigns();
    this.selectCampaign = this.selectCampaign.bind(this);
    this.selectItem = this.selectItem.bind(this);
  },
  disconnected() {
    this.endMap();
  },
  selectCampaign(properties) {
    if (properties.id) {
      this.store.campaignStore.setSelected(properties);
      this.store.itemsStore.clearItems();
      this.store.itemsStore.setSelected(null);
    }
  },
  selectItem(properties) {
    this.store.itemsStore.setSelected(properties);
  },
  render() {
    // Display products
    useEffect(() => {
      if (this.store.campaignStore.campaigns) {
        if (!this.store.productStore.selectedProduct) {
          this.addProduct(this.store.campaignStore.campaigns);
        } else {
          // FIXME: not implemented with new API
          // show only features from selected product
          const { geojson, color, contrastColor } = this.store.productStore.products[
            this.store.productStore.selectedProduct
          ];
          this.setPolygons(
            geojson.map((polygon) => ({
              polygon,
              color,
              contrastColor,
            }))
          );
        }
      }
    }, [this.store.campaignStore.campaigns]);
    // Display items of selected product
    useEffect(() => {
      if (this.store.itemsStore.itemsData) {
        this.addItems(this.store.itemsStore.itemsData);
      } else {
        this.clearItems();
      }
    }, [this.store.itemsStore.itemsData]);
  },
});
