import { makeAutoObservable, flow, computed } from 'mobx';
import { ocgDatetimeToDates, getDaysArray } from '../libs/date';

export const MAX_VALUE = 999;

function calcDatesRange(range) {
  const [start, end] = ocgDatetimeToDates(range);
  return getDaysArray(start, end).map((v) => v.toISOString().slice(0, 10));
}

/**
 * Store for timerange aware operation
 */
class TimeRangeStore {
  constructor(rootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      dateSelection: computed,
      lastDateSelection: computed,
    });
    this.rootStore = rootStore;
  }

  // All of the possible days
  days = [];

  // Days in the current range
  curDays = [];

  // Initialize range
  init(range) {
    const days = calcDatesRange(range);
    this.days = days;
    this.curDays = days;
  }

  // loading status of new date range
  status = 'unstarted';

  // Time slider min/max range values. When rangeSelection permanently change, this will be recomposed
  dateRange = [0, MAX_VALUE];

  setDateRange(start, end) {
    start = new Date(start).toISOString().substring(0, 10);
    end = new Date(end).toISOString().substring(0, 10);
    const startIndex = this.days.findIndex((d) => d === start);
    const endIndex = this.days.findIndex((d) => d === end);
    this.curDays = this.days.slice(startIndex, endIndex + 1);
  }

  recalcDateRange = flow(function* () {
    const [min, max] = this.rangeSelection;
    const [start, end] = this.dateAt(min, max);
    this.curDays = this.days.slice(this.days.indexOf(start), this.days.indexOf(end) + 1);
    this.rangeSelection = [0, MAX_VALUE];
    this.lastRangeSelection = [0, MAX_VALUE];
  });

  // Current timeslider range selection (inside the dateRange). This live change
  rangeSelection = [0, MAX_VALUE];

  setStartRangeValue(value) {
    // Checking if we are setting a too-narrow range
    if (this.checkTooNarrowRange(value, this.rangeSelection[1])) {
      return;
    }
    this.rangeSelection = [value, this.rangeSelection[1]];
  }

  setEndRangeValue(value) {
    // Checking if we are setting a too-narrow range
    if (this.checkTooNarrowRange(this.rangeSelection[0], value)) {
      return;
    }
    this.rangeSelection = [this.rangeSelection[0], value];
  }

  get dateSelection() {
    const daysCount = this.curDays.length;
    if (daysCount === 0) {
      return [null, null];
    }
    const [min, max] = this.rangeSelection;
    const [minDate, maxDate] = this.dateAt(min, max);
    return [minDate, maxDate];
  }

  // Last used timeslider range selection (inside the dateRange). This changes only after a resize operation
  lastRangeSelection = [0, MAX_VALUE];

  syncRange() {
    this.lastRangeSelection = [...this.rangeSelection];
  }

  get lastDateSelection() {
    const daysCount = this.curDays.length;
    if (daysCount === 0) {
      return [null, null];
    }
    const [min, max] = this.lastRangeSelection;
    const [minDate, maxDate] = this.dateAt(min, max);
    return [minDate, maxDate];
  }

  dateAt(min, max) {
    const daysCount = this.curDays.length;
    // v : x = MAX : len
    const indexMin = parseInt((daysCount * min) / MAX_VALUE, 10);
    const indexMax = parseInt((daysCount * max) / MAX_VALUE, 10) - 1;
    return [this.curDays[indexMin], this.curDays[indexMax]];
  }

  checkTooNarrowRange(start, end, minDayRange = 10) {
    const length = this.curDays.length;
    // MAX_VALUE : length = y : x
    const dayStart = (start * length) / MAX_VALUE;
    const dayEnd = (end * length) / MAX_VALUE;
    return dayEnd - dayStart < 10;
  }
}

export default TimeRangeStore;
