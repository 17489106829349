import { makeAutoObservable } from 'mobx';

/**
 * Store for Leaflet map data
 */
class MapStore {
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  // An array of [lat, lng]
  center = null;
  zoom = null;

  setCenter(lat, lng) {
    this.center = [lat, lng];
  }

  setZoom(zoom) {
    this.zoom = zoom;
  }
}

export default MapStore;
