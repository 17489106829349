/* global L */
// import { Map, TileLayer, Polygon } from 'leaflet/dist/leaflet-src.esm';
// import { DrawAreaSelection } from '@bopen/leaflet-area-selection';
import { toJS } from 'mobx';
import { mapContext } from '../../store';
import { ocgDatetimeToDates } from '../../libs/date';

// import 'leaflet/dist/leaflet.css';
// import '@bopen/leaflet-area-selection/dist/index.css';

const ITEM_FILL_OPACITY = 0.35;
const ITEM_OPACITY = 1.0;

/**
 * Helper function to extract a native GeoJSON from a proxy object
 * @param {Proxy} proxy
 */
function extractGeoJSON(proxy) {
  return toJS(proxy);
}

export function polygonFromGeoJSON(geoson, options = {}) {
  const { color = '37,99,235', contrastColor = '110,231,183' } = options;
  const polygon = new L.Polygon([geoson.geometry.coordinates[0].map(([lng, lat]) => [lat, lng])], {
    contrastColor: `rgb(${contrastColor})`,
    fillColor: `rgb(${color})`,
    weight: 1,
    id: geoson.properties.campaign_id,
    fillOpacity: ITEM_FILL_OPACITY,
  });
  return polygon;
}

const MapSupport = {
  initMap() {
    this.leaflet = new L.Map(this._mapElement).setView([41.901493, 12.5009157], 5);
    this.leaflet.whenReady(() => {
      // nop
    });
    this.polygons = [];
    this.layers = [];
    this.items = [];
    new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.leaflet);
    // const areaSelection = new L.Control.DrawAreaSelection({
    //   onPolygonReady: (polygon) => {
    //     console.log(JSON.stringify(polygon.toGeoJSON(3)));
    //   },
    // });
    // this.leaflet.addControl(areaSelection);
    this._handlePolygonClick = this._handlePolygonClick.bind(this);
    this.activateCampaign = this.activateCampaign.bind(this);
    this.activateItem = this.activateItem.bind(this);
    this.handleOverItem = this.handleOverItem.bind(this);
    this.handleOutItem = this.handleOutItem.bind(this);
    mapContext.provide({ map: this.leaflet, component: this });
  },
  _handlePolygonClick(properties, callback = () => {}, options = {}) {
    return (event) => {
      const { fitBounds = true } = options;
      const center = this.leaflet.getCenter();
      this.store.mapStore.setCenter(center.lat, center.lng);
      this.store.mapStore.setZoom(this.leaflet.getZoom());
      if (fitBounds) {
        this.leaflet.fitBounds(event.target.getBounds());
      }
      callback(properties);
    };
  },
  endMap() {
    // nop
  },
  activateCampaign(feature, layer) {
    layer.on(
      'click',
      this._handlePolygonClick({ ...feature.properties }, this.selectCampaign, {
        fitBounds: false,
      })
    );
  },
  handleOverItem(ev) {
    const { date, target } = ev;
    const dateObj = new Date(`${date}T00:00:00Z`);
    const { datetimes } = target.feature.properties;
    let fadeOut = true;
    datetimes.forEach((datetime) => {
      const [start, end] = ocgDatetimeToDates(datetime);
      if (start.getTime() < dateObj.getTime() && dateObj.getTime() < end.getTime()) {
        fadeOut = false;
      }
    });
    if (fadeOut) {
      target.setStyle({ fillOpacity: 0.1, opacity: 0.4 });
    }
  },
  handleOutItem(ev) {
    const { target } = ev;
    requestAnimationFrame(() => {
      target.setStyle({ fillOpacity: ITEM_FILL_OPACITY, opacity: ITEM_OPACITY });
    });
  },
  activateItem(feature, layer) {
    layer.on('click', this._handlePolygonClick({ ...feature.properties }, this.selectItem));
    layer.on('date-over', this.handleOverItem);
    layer.on('date-out', this.handleOutItem);
    // this.leaflet.addEventParent(layer);
  },
  addPolygons(polygons) {
    polygons.forEach(({ polygon, color, contrastColor }) => {
      const polyJSON = JSON.stringify(polygon);
      if (!this.polygons.includes(polyJSON)) {
        this.polygons.push(polyJSON);
        const layer = polygonFromGeoJSON(polygon, {
          color,
          contrastColor,
        });
        layer.addTo(this.leaflet);
        this.layers.push(layer);
      }
    });
  },
  /**
   * Set all of the displayed features to a set of GeoJSON polygons passed
   *
   * @param {Array} polygons an array of { geojson, color, contrastColor }
   */
  setPolygons(polygons) {
    this.polygons = [];
    this.layers.forEach((layer) => {
      layer.removeFrom(this.leaflet);
    });
    this.layers = [];
    this.addPolygons(polygons);
  },
  addProduct(geojson) {
    geojson = extractGeoJSON(geojson);
    const layer = L.geoJSON(geojson, {
      onEachFeature: this.activateCampaign,
      style: this.campaignStyle,
      name: geojson.name,
    });
    this.layers.push(layer);
    // layer.on('click', this._handlePolygonClick);
    layer.addTo(this.leaflet);
  },
  addItems(geojson) {
    const alreadyThere = this.items.find((i) => i.options.name === geojson.name);
    if (alreadyThere) {
      return;
    }
    geojson = extractGeoJSON(geojson);
    const layer = L.geoJSON(geojson, {
      onEachFeature: this.activateItem,
      style: this.itemsStyle,
    });
    this.items.push(layer);
    // layer.on('click', this._handlePolygonClick);
    layer.addTo(this.leaflet);
    requestAnimationFrame(() => {
      this.leaflet.fitBounds(layer.getBounds(), {
        animate: true,
      });
    });
  },
  clearItems() {
    this.items.forEach((layer) => {
      layer.removeFrom(this.leaflet);
    });
    this.items = [];
  },
  campaignStyle() {
    return {
      weight: 2,
      opacity: 0.7,
    };
  },
  itemsStyle(_feature) {
    return {
      weight: 1,
      fillColor: '#E74631',
      fillOpacity: ITEM_FILL_OPACITY,
      opacity: ITEM_OPACITY,
    };
  },
  getMapCoordBounds() {
    const container = this.leaflet.getContainer();
    const containerRect = container.getBoundingClientRect();
    const topLeft = this.leaflet.containerPointToLayerPoint([
      containerRect.left - containerRect.width / 2,
      containerRect.top - containerRect.height / 2,
    ]);
    const bottomRight = this.leaflet.containerPointToLayerPoint([
      containerRect.right + containerRect.width / 2,
      containerRect.bottom + containerRect.height / 2,
    ]);
    const greaterBounds = L.bounds(topLeft, bottomRight);
    return greaterBounds;
  },
};

export default MapSupport;
